import { createContext, useContext, useReducer, useMemo } from 'react'
import PropTypes from 'prop-types'
import reducer from './registrationReducer'
import useRegistrationContextInitialState from './useRegistrationContextInitialState'

const RegistrationContext = createContext()

const RegistrationProvider = ({ children }) => {
  const initialState = useRegistrationContextInitialState()

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  })

  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  return (
    <RegistrationContext.Provider value={contextValue}>
      {children}
    </RegistrationContext.Provider>
  )
}

RegistrationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useRegistrationContext = () => useContext(RegistrationContext)

export { RegistrationProvider, useRegistrationContext }
