import { ApolloError, useQuery } from '@apollo/client'
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import PropTypes, { InferProps } from 'prop-types'
import get from 'lodash/get'
import cookieNames from 'lib/cookieNames'
import { useI18nContext, i18nActions } from 'context/I18nContext'
import {
  useAdTargetingContext,
  actions as targetingActions,
} from 'context/AdTargetingContext'
import GET_AREA_QUERY from 'queries/GetAreaQuery'
import getConfig from 'next/config'
import { DEFAULT_AREA } from 'lib/areaDefaults'
import {
  useFeatureSwitch,
  useFeatureSwitchesContext,
} from 'context/FeatureSwitchesContext'
import { parseCookies } from 'nookies'
import UserDto from 'interfaces/gql/UserDto'
import AreaDto from 'interfaces/gql/AreaDto'
import CountryDto from 'interfaces/gql/CountryDto'
import featureSwitches from 'enums/feature-switches'
import Tracking from 'scripts/tracking/Tracking'
import { TRACKING_EVENT } from 'scripts/tracking'
import GET_CLOSEST_AREA_QUERY from './queries/GetClosestAreaQuery'
import GET_AUTOLOCATE_AREA_QUERY from './queries/AutolocateAreaQuery'
import GET_USER_QUERY from './queries/GetUserQuery'

const {
  publicRuntimeConfig: { BOT_AREA_ID, NEXT_ID_TOKEN_COOKIE_NAME },
} = getConfig()

type UserContextType = {
  loading?: boolean
  error?: ApolloError
  isBot?: boolean
  enableAutoLocate?: boolean
  isAuthenticated?: boolean
  user?: UserDto
  area?: AreaDto
  country?: CountryDto
  closestArea?: AreaDto
}

const UserContext = createContext<UserContextType>({
  loading: false,
  error: undefined,
  isBot: false,
  isAuthenticated: false,
})

const UserProvider = ({
  children,
  isBot,
  hasRefreshToken,
}: PropsWithChildren<Props>) => {
  const { dispatch } = useI18nContext()
  const { dispatch: targetingDispatch } = useAdTargetingContext()
  const [initializedWithRefreshToken] = useState(hasRefreshToken)
  const [hasTrackedAlgorithm, setHasTrackedAlgorithm] = useState(false)

  const cookies = parseCookies()

  const enableAutoLocate = useFeatureSwitch(
    featureSwitches.enableNewAutoLocateOutsideCities
  )

  const authenticated =
    initializedWithRefreshToken ||
    cookies?.[NEXT_ID_TOKEN_COOKIE_NAME] ||
    cookies?.[cookieNames.successfulLoginDuringSession]

  // Don't fetch the user if there are no auth tokens or if they are a bot
  const skip = !!isBot || !authenticated

  const userQueryResult = useQuery<{ user: UserDto }>(GET_USER_QUERY, {
    skip,
  })

  const closestAreaQueryResult = useQuery<{ area: AreaDto }>(
    GET_CLOSEST_AREA_QUERY,
    {
      skip: !!isBot || enableAutoLocate,
    }
  )

  const autolocateAreaQueryResult = useQuery<{ area: AreaDto }>(
    GET_AUTOLOCATE_AREA_QUERY,
    {
      skip: !!isBot || !enableAutoLocate,
    }
  )

  const botAreaQueryResult = useQuery<{ area: AreaDto }>(GET_AREA_QUERY, {
    variables: { id: BOT_AREA_ID },
    skip: !isBot,
  })

  const { updateContext } = useFeatureSwitchesContext()

  const localeCookie = cookies?.[cookieNames.locale]

  useEffect(() => {
    if (userQueryResult?.data?.user?.locale && !localeCookie) {
      dispatch({
        type: i18nActions.SET_LOCALE,
        value: userQueryResult.data.user.locale,
      })
    }
  }, [localeCookie, dispatch, userQueryResult])

  useEffect(() => {
    if (userQueryResult?.data?.user?.id) {
      targetingDispatch({
        type: targetingActions.SET_USER_TARGETING,
        payload: {
          adTargeting: userQueryResult.data.user.adTargeting,
          managedProfiles: userQueryResult.data.user.managedProfiles,
        },
      })
    }
  }, [targetingDispatch, userQueryResult])

  useEffect(() => {
    if (userQueryResult?.data?.user?.id) {
      updateContext({
        userId: userQueryResult?.data?.user?.id.toString(),
      })
    }
  }, [userQueryResult, updateContext])

  const data = parseData(
    userQueryResult,
    closestAreaQueryResult,
    autolocateAreaQueryResult,
    botAreaQueryResult,
    enableAutoLocate,
    isBot
  )

  Tracking.useTracking(
    {
      mixpanel: {
        event: TRACKING_EVENT.autoLocateAlgorithmUsed,
        properties: {
          algorithm: enableAutoLocate ? 'new' : 'old',
        },
        skip: !!authenticated || isBot || hasTrackedAlgorithm,
      },
    },
    () => {
      setHasTrackedAlgorithm(true)
    }
  )

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>
}

type QueryResult<T> = {
  data: T
  loading: boolean
  error?: ApolloError
}

const parseData = (
  userQueryResult: QueryResult<{ user: UserDto }>,
  closestAreaQueryResult: QueryResult<{ area: AreaDto }>,
  autolocateAreaQueryResult: QueryResult<{ area: AreaDto }>,
  botAreaQueryResult: QueryResult<{ area: AreaDto }>,
  enableAutoLocate: boolean,
  isBot: boolean
): UserContextType => {
  const user = get(userQueryResult ?? {}, 'data.user') as UserDto | undefined

  let area
  if (botAreaQueryResult.error || closestAreaQueryResult.error) {
    area = DEFAULT_AREA
  } else if (isBot) {
    area = get(botAreaQueryResult, 'data.area', {})
  } else {
    const areas = enableAutoLocate
      ? get(autolocateAreaQueryResult, 'data.autolocateArea', [])
      : get(closestAreaQueryResult, 'data.areas', [])
    area = areas.length > 0 ? areas[0] : {}
  }

  const loading =
    userQueryResult?.loading ||
    botAreaQueryResult.loading ||
    closestAreaQueryResult.loading ||
    autolocateAreaQueryResult.loading
  const error =
    userQueryResult?.error ||
    botAreaQueryResult.error ||
    closestAreaQueryResult.error ||
    autolocateAreaQueryResult.error

  const locationProperties = {
    area: user ? user.area : area,
    country: user?.area ? user.area.country : area.country,
    closestArea: area,
  }

  return {
    loading,
    error,
    user,
    isAuthenticated: !loading && !!user,
    isBot,
    enableAutoLocate,
    ...locationProperties,
  }
}

const useUserContext = () => useContext(UserContext)

const propTypes = {
  isBot: PropTypes.bool,
  hasRefreshToken: PropTypes.bool,
}

UserProvider.propTypes = propTypes
type Props = InferProps<typeof propTypes>

export { UserProvider, useUserContext }
