/* eslint-disable class-methods-use-this */
import Failure from './failure'

class Success<TValue> {
  private readonly _value: TValue

  constructor(value: TValue) {
    this._value = value
  }

  isSuccess(): this is Success<TValue> {
    return true
  }

  isFailure(): this is Failure<never> {
    return false
  }

  get value(): TValue {
    return this._value
  }
}

export default Success
