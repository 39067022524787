import Failure from './failure'
import Success from './success'

/**
 * Creates a result of an operation that can be either a success or a failure.
 */
class ResultFactory {
  /**
   * Creates a successful result with the provided value
   * @param value The value of the successful result
   * @returns {Success<TValue>} The successful result, including the provided value
   */
  static success<TValue>(value: TValue): Success<TValue> {
    return new Success(value)
  }

  /**
   * Creates a failed result with the provided error
   * @param error The value of the failed result
   * @returns {Failure<TError>} The failed result, including the provided error
   */
  static failure<TError>(error: TError): Failure<TError> {
    return new Failure(error)
  }
}

export default ResultFactory
