import Tracking from 'scripts/tracking'
import TRACKING_EVENT from 'scripts/tracking/tracking-event'
import { useCallback, useRef, useState } from 'react'
import { usePerformanceContext } from './PerformanceContext'

export const LoadEventListings = 'LoadEventListings'
export const LoadEventDetailsTicketed = 'LoadEventDetailsTicketed'
export const LoadEventDetailsNonTicketed = 'LoadEventDetailsNonTicketed'

export const PerformanceTrackingTypes = {
  LoadEventListings,
  LoadEventDetailsTicketed,
  LoadEventDetailsNonTicketed,
}

type PerformanceTrackingType =
  typeof PerformanceTrackingTypes[keyof typeof PerformanceTrackingTypes]

const usePerformanceTracking = (
  performanceTrackingType: PerformanceTrackingType,
  skipTracking = false
) => {
  // Use useRef to persist the hasTracked state across renders
  const performanceContext = usePerformanceContext()
  const hasTrackedRef = useRef(false)

  let skip = true
  let properties = null

  if (performanceContext) {
    const { loading, isDirectNavigation, ...rest } = performanceContext
    // Only allow tracking if:
    // 1. It hasn't been tracked before
    // 2. All other conditions are met (isDirectNavigation, duration exists, not manually skipped)
    skip =
      hasTrackedRef.current || loading || !isDirectNavigation || skipTracking
    properties = { ...rest, subtype: performanceTrackingType }

    // If we're about to track (skip is false), mark it as tracked for future renders
    if (!skip) {
      hasTrackedRef.current = true
    }
  }

  Tracking.useTracking({
    mixpanel: {
      event: TRACKING_EVENT.performanceTracking,
      properties,
      skip,
    },
  })
}

export const useManualPerformanceTracking = (
  performanceTrackingType: PerformanceTrackingType,
  performanceMarkName: string,
  skipTracking = false
) => {
  const [duration, setDuration] = useState(null)
  useMixpanelPerformanceTracking(
    duration,
    performanceTrackingType,
    skipTracking
  )

  const hasRunRef = useRef<boolean>(false)
  return useCallback(() => {
    if (hasRunRef.current || skipTracking) {
      setDuration(null)
      return
    }

    performance.mark(performanceMarkName)
    const measurement = performance.measure(
      `navigationStart to ${performanceMarkName}`,
      'navigationStart',
      performanceMarkName
    )

    setDuration(measurement.duration)
    hasRunRef.current = true
  }, [performanceMarkName, skipTracking])
}

const useMixpanelPerformanceTracking = (
  duration: number | null,
  performanceTrackingType: PerformanceTrackingType,
  skipTracking = false
) => {
  // Use useRef to persist the hasTracked state across renders
  const hasTrackedRef = useRef(false)
  const performanceContext = usePerformanceContext()

  let skip = true
  let properties = null

  if (performanceContext) {
    const { isDirectNavigation } = performanceContext
    // Only allow tracking if:
    // 1. It hasn't been tracked before
    // 2. All other conditions are met (isDirectNavigation, duration exists, not manually skipped)
    skip =
      hasTrackedRef.current ||
      !isDirectNavigation ||
      duration === null ||
      skipTracking
    properties = {
      subtype: performanceTrackingType,
      directNavigationDurationMs: duration,
    }

    // If we're about to track (skip is false), mark it as tracked for future renders
    if (!skip) {
      hasTrackedRef.current = true
    }
  }

  Tracking.useTracking({
    mixpanel: {
      event: TRACKING_EVENT.performanceTracking,
      properties,
      skip,
    },
  })
}

export type { PerformanceTrackingType }
export default usePerformanceTracking
