/* eslint-disable xss/no-mixed-html */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  about: {
    id: 'Dict.About',
    defaultMessage: 'About',
  },
  allRegions: {
    id: 'Dict.AllRegions',
    defaultMessage: 'All regions',
  },
  archive: {
    id: 'Dict.Archive',
    defaultMessage: 'Archive',
  },
  area: {
    id: 'Dict.Area',
    defaultMessage: 'Area',
  },
  day: {
    id: 'Dict.Day',
    defaultMessage: 'Day',
  },
  month: {
    id: 'Dict.Month',
    defaultMessage: 'Month',
  },
  year: {
    id: 'Dict.Year',
    defaultMessage: 'Year',
  },
  tickets: {
    id: 'Dict.Tickets',
    defaultMessage: 'Tickets',
  },
  resale: {
    id: 'Dict.Resale',
    defaultMessage: 'Resale',
  },
  advertise: {
    id: 'Dict.Advertise',
    defaultMessage: 'Advertise',
  },
  advertisingFormats: {
    id: 'Dict.AdvertisingFormats',
    defaultMessage: 'Advertising Formats',
  },
  advertisingTerms: {
    id: 'Dict.AdvertisingTerms',
    defaultMessage: 'Advertising Terms',
  },
  jobs: {
    id: 'Dict.Jobs',
    defaultMessage: 'Jobs',
  },
  discover: {
    id: 'Dict.Discover',
    defaultMessage: 'Discover',
  },
  events: {
    id: 'Dict.Events',
    defaultMessage: 'Events',
  },
  order: {
    id: 'Dict.Order',
    defaultMessage: 'Order',
  },
  getRAApps: {
    id: 'Dict.GetRAApps',
    defaultMessage: 'Get RA apps',
  },
  raGuide: {
    id: 'Dict.RAGuide',
    defaultMessage: 'RA Guide',
  },
  app: {
    id: 'Dict.App',
    defaultMessage: 'app',
  },
  raScanner: {
    id: 'Dict.RAScanner',
    defaultMessage: 'RA Scanner',
  },
  music: {
    id: 'Dict.Music',
    defaultMessage: 'Music',
  },
  myTickets: {
    id: 'Dict.MyTickets',
    defaultMessage: 'My tickets',
  },
  myProfile: {
    id: 'Dict.MyProfile',
    defaultMessage: 'My profile',
  },
  inbox: {
    id: 'Dict.Inbox',
    defaultMessage: 'Inbox',
  },
  sentItems: {
    id: 'Dict.SentItems',
    defaultMessage: 'Sent items',
  },
  composeMessage: {
    id: 'Dict.ComposeMessage',
    defaultMessage: 'Compose message',
  },
  alsoAvailableInJapanese: {
    id: 'Dict.AlsoAvailableInJapanese',
    defaultMessage: 'Also available in Japanese 日本版',
  },
  allRightsReserved: {
    id: 'Dict.AllRightsReserved',
    defaultMessage: 'All rights reserved',
  },
  privacyAndTerms: {
    id: 'Dict.PrivacyAndTerms',
    defaultMessage: '<privacy>Privacy</privacy> & <terms>Terms</terms>',
  },
  followUs: {
    id: 'Dict.FollowUs',
    defaultMessage: 'Follow us',
  },
  magazine: {
    id: 'Dict.Magazine',
    defaultMessage: 'Magazine',
  },
  promotionText: {
    id: 'Dict.VisitOurStore',
    defaultMessage: 'Visit our store for our RA merchandise',
  },
  viewMore: {
    id: 'Dict.ViewMore',
    defaultMessage: 'View more',
    description:
      'Text of button linking to, for example, viewing more news articles',
  },
  today: {
    id: 'Dict.Today',
    defaultMessage: 'Today',
  },
  follow: {
    id: 'Dict.Follow',
    defaultMessage: 'Follow',
  },
  followers: {
    id: 'Dict.Followers',
    defaultMessage: 'Followers',
  },
  phone: {
    id: 'Dict.Phone',
    defaultMessage: 'Phone',
  },
  links: {
    id: 'Dict.Links',
    defaultMessage: 'Links',
  },
  unfollow: {
    id: 'Dict.Unfollow',
    defaultMessage: 'Unfollow',
  },
  status: {
    id: 'Dict.Status',
    defaultMessage: 'Status',
  },
  price: {
    id: 'Dict.Price',
    defaultMessage: 'Price',
  },
  frequency: {
    id: 'Dict.Frequency',
    defaultMessage: 'Frequency',
  },
  tier: {
    id: 'Dict.Tier',
    defaultMessage: 'Tier',
  },
  startDate: {
    id: 'Dict.StartDate',
    defaultMessage: 'Start Date',
  },
  open: {
    id: 'Dict.Open',
    defaultMessage: 'Open',
  },
  closed: {
    id: 'Dict.Closed',
    defaultMessage: 'Closed',
  },
  region: {
    id: 'Dict.Region',
    defaultMessage: 'Region',
  },
  regions: {
    id: 'Dict.Regions',
    defaultMessage: 'Regions',
  },
  country: {
    id: 'Dict.Country',
    defaultMessage: 'Country',
  },
  search: {
    id: 'Dict.Search',
    defaultMessage: 'Search',
  },
  searchEventsClubsArtistsPromoters: {
    id: 'Dict.SearchEventsClubsArtistsPromoters',
    defaultMessage: 'Search events, clubs, artists, promoters...',
  },
  profile: {
    id: 'Dict.Profile',
    defaultMessage: 'Profile',
  },
  notifications: {
    id: 'Dict.Notifications',
    defaultMessage: 'Notifications',
  },
  accountSettings: {
    id: 'Dict.AccountSettings',
    defaultMessage: 'Account settings',
  },
  home: {
    id: 'Dict.Home',
    defaultMessage: 'Home',
  },
  all: {
    id: 'Dict.All',
    defaultMessage: 'All',
  },
  podcast: {
    id: 'Dict.Podcast',
    defaultMessage: 'Podcast',
  },
  raPodcast: {
    id: 'Dict.RaPodcast',
    defaultMessage: 'RA Podcast',
  },
  reviews: {
    id: 'Dict.Reviews',
    defaultMessage: 'Reviews',
  },
  techReviews: {
    id: 'Dict.TechReviews',
    defaultMessage: 'Tech reviews',
  },
  eventReviews: {
    id: 'Dict.EventReviews',
    defaultMessage: 'Event reviews',
  },
  news: {
    id: 'Dict.News',
    defaultMessage: 'News',
  },
  mixOfTheDay: {
    id: 'Dict.MixOfTheDay',
    defaultMessage: 'Mix of the day',
  },
  playlists: {
    id: 'Dict.Playlists',
    defaultMessage: 'Playlists',
  },
  features: {
    id: 'Dict.Features',
    defaultMessage: 'Features',
  },
  films: {
    id: 'Dict.Films',
    defaultMessage: 'Films',
  },
  raExchange: {
    id: 'Dict.RAExchange',
    defaultMessage: 'RA Exchange',
  },
  guides: {
    id: 'Dict.Guides',
    defaultMessage: 'Guides',
  },
  tech: {
    id: 'Dict.Tech',
    defaultMessage: 'Tech',
  },
  interested: {
    id: 'Dict.Interested',
    defaultMessage: 'Interested',
  },
  realName: {
    id: 'Dict.RealName',
    defaultMessage: 'Real name',
    description: 'An artists real name',
  },
  startTyping: {
    id: 'Dict.StartTyping',
    defaultMessage: 'Start typing...',
    description: 'Placeholder text for search input box',
  },
  filter: {
    id: 'Dict.Filter',
    defaultMessage: 'Filter',
    description: 'Sub nav filter label',
  },
  filterBy: {
    id: 'Dict.FilterBy',
    defaultMessage: 'Filter by',
    description: 'Filter options for refining results, e.g. Filter by genre',
  },
  location: {
    id: 'Dict.Location',
    defaultMessage: 'Location',
    description: 'Area in the world',
  },
  distance: {
    id: 'Dict.Distance',
    defaultMessage: 'Distance',
  },
  share: {
    id: 'Dict.Share',
    defaultMessage: 'Share',
  },
  label: {
    id: 'Dict.Label',
    defaultMessage: 'Label',
    description: 'Record Label',
  },
  labels: {
    id: 'Dict.Labels',
    defaultMessage: 'Labels',
    description: 'Record Labels',
  },
  back: {
    id: 'Dict.Back',
    defaultMessage: 'Back',
  },
  sitemap: {
    id: 'Dict.Sitemap',
    defaultMessage: 'Sitemap',
  },
  overview: {
    id: 'Dict.Overview',
    defaultMessage: 'Overview',
  },
  generalInformation: {
    id: 'Dict.GeneralInformation',
    defaultMessage: 'General Information',
  },
  communityConnections: {
    id: 'Dict.CommunityConnections',
    defaultMessage: 'Community Connections',
  },
  raTickets: {
    id: 'Dict.RaTickets',
    defaultMessage: 'RA Tickets',
  },
  partners: {
    id: 'Dict.Partners',
    defaultMessage: 'Partners',
  },
  advertising: {
    id: 'Dict.Advertising',
    defaultMessage: 'Advertising',
  },
  contribute: {
    id: 'Dict.Contribute',
    defaultMessage: 'Contribute',
  },
  privacy: {
    id: 'Dict.Privacy',
    defaultMessage: 'Privacy',
  },
  terms: {
    id: 'Dict.Terms',
    defaultMessage: 'Terms',
  },
  cookiePolicy: {
    id: 'Dict.CookiePolicy',
    defaultMessage: 'Cookie Policy',
  },
  cookies: {
    id: 'Dict.Cookies',
    defaultMessage: 'Cookies',
  },
  aboutRaTickets: {
    id: 'Dict.AboutRaTickets',
    defaultMessage: 'About RA Tickets',
    description: 'Only translate "About"',
  },
  promoter: {
    id: 'Dict.Promoter',
    defaultMessage: 'Promoter',
  },
  promoters: {
    id: 'Dict.Promoters',
    defaultMessage: 'Promoters',
  },
  promoterFaq: {
    id: 'Dict.PromoterFaq',
    defaultMessage: 'Promoter FAQ',
  },
  faqs: {
    id: 'Dict.Faqs',
    defaultMessage: "FAQ's",
  },
  payments: {
    id: 'Dict.Payments',
    defaultMessage: 'Payments',
  },
  raTicketsReporting: {
    id: 'Dict.RaTicketsReporting',
    defaultMessage: 'RA Tickets Reporting',
    description: 'Only translate "Reporting"',
  },
  jointDataControllership: {
    id: 'Dict.JointDataControllership',
    defaultMessage: 'Joint Data Controllership',
  },
  sellTicketsOnRA: {
    id: 'Dict.SellTicketsOnRA',
    defaultMessage: 'Sell tickets on RA',
  },
  reporting: {
    id: 'Dict.Reporting',
    defaultMessage: 'Reporting',
  },
  ticketScanner: {
    id: 'Dict.TicketScanner',
    defaultMessage: 'Ticket Scanner',
  },
  partnerships: {
    id: 'Dict.Partnerships',
    defaultMessage: 'Partnerships',
  },
  displayLanguage: {
    id: 'Dict.DisplayLanguage',
    defaultMessage: 'Language',
    description: 'A label to show current language. Example "Language: "',
  },
  language: {
    id: 'Dict.Language',
    defaultMessage: 'Language',
  },
  languageNativeName: {
    id: 'Dict.LanguageNativeName',
    defaultMessage: 'English',
  },
  born: {
    id: 'Dict.Born',
    defaultMessage: 'Born',
  },
  based: {
    id: 'Dict.Based',
    defaultMessage: 'Based',
  },
  bornLocation: {
    id: 'Dict.BornLocation',
    defaultMessage: '{country} (Born)',
  },
  basedLocation: {
    id: 'Dict.BasedLocation',
    defaultMessage: '{residentCountry} (Based)',
  },
  aliases: {
    id: 'Dict.Aliases',
    defaultMessage: 'Aliases',
  },
  next: {
    id: 'Dict.Next',
    defaultMessage: 'Next',
  },
  previous: {
    id: 'Dict.Previous',
    defaultMessage: 'Previous',
  },
  nextWeek: {
    id: 'Dict.NextWeek',
    defaultMessage: 'Next week',
  },
  previousWeek: {
    id: 'Dict.PreviousWeek',
    defaultMessage: 'Previous week',
  },
  popular: {
    id: 'Dict.Popular',
    defaultMessage: 'Popular',
  },
  english: {
    id: 'Dict.English',
    defaultMessage: 'English',
    description: 'Name of the English language',
  },
  japanese: {
    id: 'Dict.Japanese',
    defaultMessage: 'Japanese',
    description: 'Name of the Japanese language',
  },
  spanish: {
    id: 'Dict.Spanish',
    defaultMessage: 'Spanish',
    description: 'Name of the Spanish language',
  },
  french: {
    id: 'Dict.French',
    defaultMessage: 'French',
    description: 'Name of the French language',
  },
  italian: {
    id: 'Dict.Italian',
    defaultMessage: 'Italian',
    description: 'Name of the Italian language',
  },
  german: {
    id: 'Dict.German',
    defaultMessage: 'German',
    description: 'Name of the German language',
  },
  dutch: {
    id: 'Dict.Dutch',
    defaultMessage: 'Dutch',
    description: 'Name of the Dutch language',
  },
  portuguese: {
    id: 'Dict.Portuguese',
    defaultMessage: 'Portuguese',
    description: 'Name of the Portuguese language',
  },
  chinese: {
    id: 'Dict.Chinese',
    defaultMessage: 'Chinese',
    description: 'Name of the Chinese language',
  },
  polish: {
    id: 'Dict.Polish',
    defaultMessage: 'Polish',
    description: 'Name of the Polish language',
  },
  featured: {
    id: 'Dict.Featured',
    defaultMessage: 'Featured',
  },
  raNews: {
    id: 'Dict.RANews',
    defaultMessage: 'RA News',
  },
  raEditorial: {
    id: 'Dict.RAEditorial',
    defaultMessage: 'RA Editorial',
  },
  typeHere: {
    id: 'Dict.TypeHere',
    defaultMessage: 'Type here',
  },
  remove: {
    id: 'Dict.Remove',
    defaultMessage: 'Remove',
  },
  support: {
    id: 'Dict.Support',
    defaultMessage: 'Support',
  },
  priceWithCurrency: {
    id: 'Dict.PriceWithCurrency',
    defaultMessage: 'Price ({currency})',
  },
  update: {
    id: 'Dict.Update',
    defaultMessage: 'Update',
  },
  readMore: {
    id: 'Dict.ReadMore',
    defaultMessage: 'Read more',
  },
  readLess: {
    id: 'Dict.ReadLess',
    defaultMessage: 'Read less',
  },
  guide: {
    id: 'Dict.Guide',
    defaultMessage: 'Guide',
  },
  dateEstablished: {
    id: 'Dict.DateEstablished',
    defaultMessage: 'Date est.',
  },
  media: {
    id: 'Dict.Media',
    defaultMessage: 'Media',
  },
  type: {
    id: 'Dict.Type',
    defaultMessage: 'Type',
  },
  pleaseSelect: {
    id: 'Dict.PleaseSelect',
    defaultMessage: 'Please select',
  },
  title: {
    id: 'Dict.Title',
    defaultMessage: 'Title',
  },
  done: {
    id: 'Dict.Done',
    defaultMessage: 'Done',
  },
  raSince: {
    id: 'Dict.RASince',
    defaultMessage: 'RA Since',
  },
  blog: {
    id: 'Dict.Blog',
    defaultMessage: 'Blog',
  },
  website: {
    id: 'Dict.Website',
    defaultMessage: 'Website',
  },
  loadMore: {
    id: 'Dict.LoadMore',
    defaultMessage: 'Load more',
  },
  whatWeDo: {
    id: 'Dict.WhatWeDo',
    defaultMessage: 'What We Do',
  },
  workWithUs: {
    id: 'Dict.WorkWithUs',
    defaultMessage: 'Work With Us',
  },
  ourValues: {
    id: 'Dict.OurValues',
    defaultMessage: 'Our Values',
  },
  diversity: {
    id: 'Dict.Diversity',
    defaultMessage: 'Diversity',
  },
  communityProjects: {
    id: 'Dict.CommunityProjects',
    defaultMessage: 'Community Projects',
  },
  staff: {
    id: 'Dict.Staff',
    defaultMessage: 'Staff',
  },
  contact: {
    id: 'Dict.Contact',
    defaultMessage: 'Contact',
  },
  club: {
    id: 'Dict.Club',
    defaultMessage: 'Club',
  },
  paymentDetails: {
    id: 'Dict.PaymentDetails',
    defaultMessage: 'Payment details',
  },
  cancel: {
    id: 'Dict.Cancel',
    defaultMessage: 'Cancel',
  },
  basket: {
    id: 'Dict.Basket',
    defaultMessage: 'Basket',
  },
  total: {
    id: 'Dict.Total',
    defaultMessage: 'Total',
  },
  bankDetails: {
    id: 'Dict.BankDetails',
    defaultMessage: 'Bank details',
  },
  updateBankDetails: {
    id: 'Dict.UpdateBankDetails',
    defaultMessage: 'Update bank details',
  },
  createBankDetails: {
    id: 'Dict.CreateBankDetails',
    defaultMessage: 'Create bank details',
  },
  cancelsUpdatingBankDetails: {
    id: 'Dict.CancelsUpdatingBankDetails',
    defaultMessage: 'Cancels updating bank details',
  },
  individual: {
    id: 'Dict.Individual',
    defaultMessage: 'Individual',
  },
  business: {
    id: 'Dict.Business',
    defaultMessage: 'Business',
  },
  add: {
    id: 'Dict.Add',
    defaultMessage: 'Add',
  },
  dragAndDrop: {
    id: 'Dict.DragAndDrop',
    defaultMessage: 'Drag and drop some files here, or click to select files',
  },
  archived: {
    id: 'Dict.Archived',
    defaultMessage: 'Archived',
  },
  current: {
    id: 'Dict.Current',
    defaultMessage: 'Current',
  },
  denied: {
    id: 'Dict.Denied',
    defaultMessage: 'Denied',
  },
  pending: {
    id: 'Dict.Pending',
    defaultMessage: 'Pending',
  },
  description: {
    id: 'Dict.Description',
    defaultMessage: 'Description',
  },
  added: {
    id: 'Dict.Added',
    defaultMessage: 'Added',
  },
  removed: {
    id: 'Dict.Removed',
    defaultMessage: 'Removed',
  },
  approve: {
    id: 'Dict.Approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'Dict.Reject',
    defaultMessage: 'Reject',
  },
  restore: {
    id: 'Dict.Restore',
    defaultMessage: 'Restore',
  },
  confirm: {
    id: 'Dict.Confirm',
    defaultMessage: 'Confirm',
  },
  link: {
    id: 'Dict.Link',
    defaultMessage: 'Link',
  },
  close: {
    id: 'Dict.Close',
    defaultMessage: 'Close',
  },
  help: {
    id: 'Dict.Help',
    defaultMessage: 'Help',
  },
  following: {
    id: 'Dict.Following',
    defaultMessage: 'Following',
  },
  artist: {
    id: 'Dict.Artist',
    defaultMessage: 'Artist',
  },
  notFollowingAnyone: {
    id: 'Dict.NotFollowingAnyone',
    defaultMessage: "You aren't following anyone",
  },
  followProfiles: {
    id: 'Dict.FollowProfiles',
    defaultMessage:
      'Follow some Artists, Labels, Clubs and Promoters and they will appear here',
  },
  pronouns: {
    id: 'Dict.Pronouns',
    defaultMessage: 'Pronouns',
  },
  accept: {
    id: 'Dict.Accept',
    defaultMessage: 'Accept',
  },
  decline: {
    id: 'Dict.Decline',
    defaultMessage: 'Decline',
  },
  showResults: {
    id: 'Events.ShowResults',
    defaultMessage: 'Show results',
  },
  showResultsWithCount: {
    id: 'Events.ShowResultsWithCount',
    defaultMessage:
      '{count, plural, one {Show 1 result} other {Show {count} results}}',
  },
  reset: {
    id: 'Events.Reset',
    defaultMessage: 'Reset',
  },
  thisWeekend: {
    id: 'Events.ThisWeekend',
    defaultMessage: 'This weekend',
  },
  oops: {
    id: 'Dict.Oops',
    defaultMessage: 'Oops!',
  },
  none: {
    id: 'Dict.None',
    defaultMessage: 'None',
  },
  or: {
    id: 'Dict.Or',
    defaultMessage: 'or',
  },
  pay: {
    id: 'Dict.Pay',
    defaultMessage: 'Pay',
  },
  payNow: {
    id: 'Dict.PayNow',
    defaultMessage: 'Pay now',
  },
  savedEvents: {
    id: 'Dict.SavedEvents',
    defaultMessage: 'Saved events',
  },
  admin: {
    id: 'Dict.Admin',
    defaultMessage: 'Admin',
  },
  continue: {
    id: 'Dict.Continue',
    defaultMessage: 'Continue',
  },
})

export default messages
