/* eslint-disable class-methods-use-this */
import Success from './success'

class Failure<TError> {
  private readonly _error: TError

  constructor(error: TError) {
    this._error = error
  }

  isSuccess(): this is Success<never> {
    return false
  }

  isFailure(): this is Failure<TError> {
    return true
  }

  get error(): TError {
    return this._error
  }
}

export default Failure
