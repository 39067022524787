import { parseCookies } from 'nookies'
import { Result, ResultFactory } from 'lib/result'
import setCookie from 'set-cookie-parser'

export type AuthTokens = {
  idToken: string
  cookieHeader: string
  refreshToken: string
}

/**
 * This will get the auth tokens from the context and return them
 * as a result.
 *
 * @param context
 */
const getServerSideAuthTokens = (context): Result<AuthTokens, Error> => {
  /**
   * There are two cases that can happen here
   * 1. Case that the user has both the id token and the refresh token on the request (they are already logged in)
   *   - In this case we can use the cookie header in the request as is
   * 2. Case that the users token has just been refreshed and the tokens exist as set-cookie headers on the response
   *   - In this case we need to extract the set-cookie headers from the response and convert them to a cookie header
   */
  try {
    const { req, res } = context

    const cookies = parseCookies(context)
    if (hasAuthCookies(cookies)) {
      return ResultFactory.success({
        idToken: cookies[process.env.NEXT_ID_TOKEN_COOKIE_NAME] ?? null,
        cookieHeader: req?.headers?.cookie,
        refreshToken:
          cookies[process.env.NEXT_REFRESH_TOKEN_COOKIE_NAME] ?? null,
      })
    }

    if (!res) {
      return ResultFactory.success({
        idToken: null,
        cookieHeader: '',
        refreshToken: null,
      })
    }

    return extractAuthTokensFromResponse(res)
  } catch (error) {
    return ResultFactory.failure(error)
  }
}

const hasAuthCookies = (cookies: { [key: string]: string }) => {
  return !!cookies[process.env.NEXT_ID_TOKEN_COOKIE_NAME]
}

const extractAuthTokensFromResponse = (res) => {
  const setCookieHeaders: string[] = res.getHeader('set-cookie') ?? []
  if (noHeadersExist(setCookieHeaders)) {
    return ResultFactory.success({
      idToken: null,
      cookieHeader: '',
      refreshToken: null,
    })
  }

  const { parsedSetCookieHeaders, idTokenCookie, refreshTokenCookie } =
    parseSetCookieHeaders(setCookieHeaders)

  if (!idTokenCookie && !refreshTokenCookie) {
    return ResultFactory.success({
      idToken: null,
      cookieHeader: '',
      refreshToken: null,
    })
  }

  const idToken = idTokenCookie?.value ?? null
  const refreshToken = refreshTokenCookie?.value ?? null

  const cookieHeader = convertToCookieHeader(parsedSetCookieHeaders)

  return ResultFactory.success({
    idToken,
    cookieHeader,
    refreshToken,
  })
}

const noHeadersExist = (setCookieHeaders: string[]) => {
  return !setCookieHeaders || setCookieHeaders.length === 0
}

const parseSetCookieHeaders = (setCookieHeaders: string[]) => {
  const parsedSetCookieHeaders = setCookie.parse(setCookieHeaders)

  const idTokenCookie = parsedSetCookieHeaders.find(
    (cookie: { name: string; value: string }) =>
      cookie.name === process.env.NEXT_ID_TOKEN_COOKIE_NAME
  )

  const refreshTokenCookie = parsedSetCookieHeaders.find(
    (cookie: { name: string; value: string }) =>
      cookie.name === process.env.NEXT_REFRESH_TOKEN_COOKIE_NAME
  )
  return {
    parsedSetCookieHeaders,
    idTokenCookie,
    refreshTokenCookie,
  }
}

const convertToCookieHeader = (parsedCookies) => {
  let cookieHeader = ''

  parsedCookies.forEach((cookie) => {
    cookieHeader += `${cookie.name}=${cookie.value}; `
  })
  return cookieHeader
}

export default getServerSideAuthTokens
