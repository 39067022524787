import base64url from 'base64url'
import { useRouter } from 'next/router'

const useRegistrationContextInitialState = () => {
  const { query } = useRouter()

  if (query?.oauth) {
    try {
      return JSON.parse(base64url.decode(query.oauth as string)) ?? {}
    } catch {
      return {}
    }
  }

  return {}
}

export default useRegistrationContextInitialState
