import actions from './registrationActions'

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_REGISTRATION_VALUES:
      return {
        ...state,
        ...action.payload,
      }
    case actions.REGISTRATION_COMPLETED:
      return {
        ...state,
        email: action.payload,
        hasCompletedRegistration: true,
      }

    default:
      return state
  }
}

export default reducer
